import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { NetworkErrorAlert } from "../../components/infoPopup/infoPopup";
import CustomLoader from "../../components/loader/loader";
import RequestAuthenticate from "../../components/login/requestAuthenticate";
import SuccessModal from "../../components/login/successModal";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import * as Actions from "../../constants/baseUrl";
import RootLayout from "../../layouts/RootLayout";
import NRIStartups from "../NRIStartups/listing";
import DynamicHomepageDetails from "../admin/dynamicHomepages/details";
import PageDetails from "../admin/pages/details";
import GroupAppointment from "../appointment/GroupAppointment";
import AutomateCampaign from "../automateCampaign/automateCampaign";
import BlogDetails from "../blog/details";
import Blogs from "../blog/getAll";
import ChatAI from "../chatAI/chatAI";
import Cohorts from "../cohorts/getAll";
import Contact from "../contact";
import Coordinator from "../coordinator/coordinator";
import Corporates from "../corporates/corporates";
import CourseDetails from "../courses/details";
import DemoDetails from "../demos/details";
import EventDetails from "../events/details";
import Events from "../events/getAll";
import Freelancers from "../freelancers/listing";
import Home from "../home/Home";
import Meetups from "../meetups/meetups";
import Mentors from "../mentors/listing";
import RegisterNewsletter from "../newsletter/register";
import Properties from "../property/getAll";
import PropertyEventDetails from "../propertyEvents/details";
import PropertyEvents from "../propertyEvents/getAll";
import ServiceProvider from "../serviceProviders/listing";
import Speakers from "../speakers/speakers";
import Startups from "../startups/startup";
import CreatePassword from "./changepassword/changepassword";

const AuthPage = () => {
  const navigate = useNavigate();

  const currentHost = window.location.host;
  const currentPathname = window.location.pathname;

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [signupErrorMsg, setSignupErrorMsg] = useState("");

  const siteName = SubDomainRouter("title");
  const favicon = SubDomainRouter("favicon");
  const homePageRoute = SubDomainRouter("homePageRoute");
  const homeNavItem = SubDomainRouter("homeNavItem");

  const configStatus = useSelector((state) => state.config.status);
  const configData = useSelector((state) => state.config.value);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    if (configData && configStatus !== 'failed') {
      document.title = siteName
        ? siteName
        : String(currentHost).toUpperCase();

      if (currentPathname === "/") {
        if (homePageRoute === "/home") {
          setConfigLoading(false);
          return;
        } else if (
          !homeNavItem ||
          homeNavItem === "hide" ||
          homePageRoute !== "/"
        ) {
          navigate(homePageRoute);
        }
      }

      setConfigLoading(false);
    }
  }, [
    configData,
    currentPathname,
    homeNavItem,
    homePageRoute,
    navigate,
    siteName,
  ]);

  useEffect(() => {
    if (favicon) {
      const updateFavicon = (favicon) => {
        const link =
          document.querySelector("link[rel~='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = favicon;
        document.getElementsByTagName("head")[0].appendChild(link);
      };

      updateFavicon(favicon);
    }
  }, [favicon, siteName]);

  //   Handles redirects to zoom meeting
  useEffect(() => {
    //   When user visits /zoom, redirects to zoom meeting
    if (currentPathname === "/zoom") {
      // Redirect to zoom meeting
      window.location.href = "https://us06web.zoom.us/j/84378343328";
    }
  }, [currentPathname, navigate]);

  const formRef = useRef(null);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (mode) {
      const request = {
        Email: e.currentTarget["email"].value,
        Password: e.currentTarget["password"].value,
        //?====== for prod + test ===========
        username: `${e.currentTarget["email"].value}_${currentHost === "localhost:3000" ? 'localhost' : currentHost}`,
        host: currentHost,
      };
      axios
        .post(`${Actions.AUTH_URL}Auth/Login`, request)
        .then((res) => {
          let data = res.data;
          if (
            data === "DOESNOTEXIST" ||
            data.AuthToken === null ||
            data.AuthToken === "" ||
            data.AuthToken === undefined
          ) {
            localStorage.setItem("isValidUser", false);
            toast.error("In valid credentials. Please try again.", {
              autoClose: 3000,
              hideProgressBar: true,
              draggable: true,
            });
          } else {
            localStorage.setItem("isValidUser", true);
            localStorage.setItem("token", data.AuthToken);
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          handleApiError(error);
          setLoading(false);
          toast.error("Something went wrong. Please try again later.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    } else {
      let request = {
        Password: e.currentTarget["createpassword"].value,
        Role: e.currentTarget["role"].value,
        Email: e.currentTarget["newEmail"].value,
        Phone: e.currentTarget["phone"].value,
        FirstName: e.currentTarget["firstname"].value,
        LastName: e.currentTarget["lastname"].value,
        FullName: `${e.currentTarget["firstname"].value} ${e.currentTarget["lastname"].value}`,
        //?====== for prod + test ===========
        username: `${e.currentTarget["newEmail"].value}_${currentHost === "localhost:3000" ? 'localhost' : currentHost}`,
        host: currentHost,
      };
      axios
        .post(`${Actions.AUTH_URL}Users/PostUser`, request)
        .then((res) => {
          console.log(res?.data);
          if (res?.data?.Data === "SUCCESS") {
            setShowSuccessModal(true);
            formRef.current.reset();
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          handleApiError(error);
          setLoading(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };

  const handleApiError = (error) => {
    console.log(error);
    if (error?.message === "Network Error") {
      NetworkErrorAlert(error?.message);
    }

    if (error.response?.data) {
      // Use a regular expression to capture everything until the first full stop or line break
      const match = error.response.data.match(
        /System\.ApplicationException: (.*?)(\n|$)/s
      );

      if (match && match.length > 1) {
        const errorMessage = match[1];
        setSignupErrorMsg(errorMessage.trim());
        console.log(errorMessage);
      } else {
        // If the error message is not in the expected format, log it for debugging purposes
        console.error("Unexpected error format:", error.response.data);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {siteName ? siteName : String(currentHost).toUpperCase()}
        </title>
      </Helmet>
      {/* If not authorized */}

      {configLoading && configStatus === 'loading' ? (
        <CustomLoader height={"100vh"} className={"pt-0 mt-0 pb-0 mb-0"} />
      ) : (
        <>
          <Routes>
            <Route
              path="*"
              exact
              element={
                <div className={`app app--is-${mode ? "login" : "signup"}`}>
                  {loading ? (
                    <>
                      <span className="register-loader theme-text-2"> </span>
                      <span className="spinnerOverlay"></span>
                    </>
                  ) : null}
                  <RequestAuthenticate
                    mode={mode}
                    formRef={formRef}
                    signupErrorMsg={signupErrorMsg}
                    setMode={setMode}
                    setLoading={setLoading}
                    onSubmit={handleSubmit}
                    onChange={function (e) {
                      console.log(e.target.value);
                    }}
                  />
                </div>
              }
            />

            <Route element={<RootLayout />}>
              <Route
                path={homePageRoute === "/" ? "/" : "/home"}
                exact
                element={<Home />}
              />

              <Route path="/pages/details/:id" element={<PageDetails />} />

              <Route path="/CreatePassword" element={<CreatePassword />} />
              <Route path="/corporates" element={<Corporates />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/courses" element={<Blogs />} />
              <Route path="/courses/details/:id" element={<CourseDetails />} />

              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/details/:id" element={<BlogDetails />} />

              <Route path="/demos" element={<Blogs />} />
              <Route path="/demos/details/:id" element={<DemoDetails />} />

              <Route path={"/events"} element={<Events />} />
              <Route path="/events/details/:id" element={<EventDetails />} />

              <Route path="/coordinator/join" element={<Coordinator />} />

              {/* PropertyEvent */}
              <Route path={"/propertyevent/items"} element={<PropertyEvents />} />
              <Route
                path="/propertyevent/items/details/:id"
                element={<PropertyEventDetails />}
              />
              <Route
                path="/homepages/details/:id"
                element={<DynamicHomepageDetails />}
              />

              <Route path={"/code-ai"} element={<ChatAI />} />
              <Route path={"/startup/items"} element={<Startups />} />
              <Route path="/meetups/items" element={<Meetups />} />
              <Route path="/speaker/items" element={<Speakers />} />
              <Route path="/service-provider/items" element={<ServiceProvider />} />
              <Route path={"/mentor/items"} element={<Mentors />} />
              <Route path={"/freelancer/items"} element={<Freelancers />} />
              <Route path={"/NRI-startup/items"} element={<NRIStartups />} />
              <Route path={"/property/items"} element={<Properties />} />
              <Route path="/cohort/items" element={<Cohorts />} />

              <Route path="/newsletter" element={<RegisterNewsletter />} />
              <Route path="/appointment" element={<GroupAppointment />} />
              <Route path="/subscribe" element={<RegisterNewsletter />} />


              <Route
                path="/campaigns/:entityType"
                element={<AutomateCampaign />}
              />

            </Route>
          </Routes>

          {/*  */}

          {showSuccessModal ? (
            <SuccessModal
              setMode={setMode}
              show={showSuccessModal}
              setshow={setShowSuccessModal}
              onHide={() => {
                setShowSuccessModal(false);
              }}
            />
          ) : null}

        </>
      )}


      <ToastContainer limit={2} position="bottom-right" />
    </>
  );
};
export default AuthPage;
