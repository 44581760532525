import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicUserRoleFieldset = ({ field, onFieldChange, onRemove }) => {
  const onFieldNameChange = (value) => {
    onFieldChange({ ...field, key: value });
  };

  const onFileContentChange = (value) => {
    onFieldChange({ ...field, value: value });
  };

  return (
    <Col md={12} className="dynamicField pt-3">
      <div className="d-flex gap-3 w-100 flex-sm-row flex-column-reverse align-items-sm-start align-items-end mb-3">
        <div className="d-flex align-items-center gap-3 flex-sm-row flex-column flex-grow-1 w-100">
          <Form.Group className="mb-md-3 flex-grow-1 w-100 form-floating position-relative">
            <Form.Control
              type="text"
              className="w-100"
              name="userRoleKey"
              placeholder="Key"
              value={field.key}
              onChange={(e) => onFieldNameChange(e.target.value)}
            />
            <Form.Label className="form-label">Key</Form.Label>
          </Form.Group>
          <Form.Group className="mb-md-3 flex-grow-1 w-100 form-floating position-relative">
            <Form.Control
              type="text"
              className="w-100"
              name="userRoleValue"
              placeholder="Value"
              value={field.value}
              onChange={(e) => onFileContentChange(e.target.value)}
            />
            <Form.Label className="form-label">Value</Form.Label>
          </Form.Group>
        </div>
        <Button
          className="clickable actionDltBtn btn border-danger"
          onClick={onRemove}
        >
          <span className="addBttn d-block">
            <Trash />
          </span>
        </Button>
      </div>
    </Col>
  );
};

export default DynamicUserRoleFieldset;
