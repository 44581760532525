import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../../constants/baseUrl";
import withRouter from "../../../constants/withRouter";
import SingleFieldset from "../../../components/SingleFieldset/SingleFieldset";

function AddEditPage(props) {
  const item_id = props.params.id;
  const actionType = props.params.actionType;

  const navigate = useNavigate();
  const entityType = "page";
  const pageAlias = SubDomainRouter("pageAlias");

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [reqLoading, setReqLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    page_title: "",
    style_link: "",
    custom_css: "",
    html_content: "",
  });
  const [styleLinks, setStyleLinks] = useState([{ style_link: "" }]);
  const [scriptLinks, setScriptLinks] = useState([{ script_link: "" }]);

  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const populateList = async (id) => {
    try {
      if (!id) {
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${Actions.BASE_URL}items/${entityType}/${id}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const resData = await response.json();
      setStyleLinks(resData?.data?.styleLinks);
      setScriptLinks(resData?.data?.scriptLinks);
      setFormData({
        ...resData?.data,
      });
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const step1Fields = ["page_title", "html_content"];

  const areFieldsFilled = (fieldNames, state) => {
    return fieldNames.every((fieldName) => state[fieldName]);
  };

  const isStep1FieldsFilled = areFieldsFilled(step1Fields, formData);

  useEffect(() => {
    populateList(item_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id]);

  const addField = (setField, field) => {
    setField(field ? [...field, {}] : [{}]);
  };

  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields?.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  // *--------------------------------

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isStep1FieldsFilled) {
      setHasValue(false);
      setIsValid(true);
      setReqLoading(true);

      const reqData = {
        type: entityType,
        host: window.location.host,
        header: {
          host: window.location.host,
          userId: myUserId,
        },
        data: {
          userId: myUserId,
          ...formData,
          styleLinks: styleLinks,
          scriptLinks: scriptLinks,
        },
      };

      if (item_id && !actionType) {
        axios
          .put(`${Actions.BASE_URL}items/${entityType}/${item_id}`, reqData, {
            headers: {
              Authorization: "Bearer " + myToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            navigate(`/admin/${entityType}s/`);
          })
          .catch((error) => {
            console.error("API request failed:", error);
          })
          .finally(() => {
            setReqLoading(false);
          });
      } else {
        axios
          .post(`${Actions.BASE_URL}items`, reqData, {
            headers: {
              Authorization: "Bearer " + myToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            navigate(`/admin/${entityType}s`);
          })
          .catch((error) => {
            console.error("API request failed:", error);
          })
          .finally(() => {
            setReqLoading(false);
          });
      }
    } else {
      setHasValue(true);
      setIsValid(true);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Container fluid="lg" className="demosEditSection">
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Row>
          <Form action="" role="form" onSubmit={handleSubmit}>
            <h1 className="text-center text-capitalize">
              {item_id && !actionType
                ? "Edit"
                : item_id && actionType
                ? "Clone"
                : "Add"}
              &nbsp;
              {pageAlias ? pageAlias : "propertyEvent"}
            </h1>
            <div className="demosEditHeader text-center mb-3 mb-lg-5">
              <p>Add details below</p>
            </div>

            <fieldset className="wizard-fieldset show demosAddForm">
              <Row className="mx-0">
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      autoFocus
                      type="text"
                      className={`italicInput ${
                        isValid && hasValue && !formData.page_title
                          ? "border-danger"
                          : ""
                      }`}
                      id="page_title"
                      required
                      value={formData?.page_title}
                      placeholder="Enter Event Title"
                      name="page_title"
                      onChange={(e) =>
                        handleFieldChange("page_title", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="page_title">
                      Page Title
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <h5 className="theme-text-2">Styles</h5>
                  <hr className="mb-2" />
                </Col>

                <Col md={12} className="ps-0">
                  {styleLinks?.map((field, index) => (
                    <React.Fragment key={index}>
                      <SingleFieldset
                        fieldKey={"style_link"}
                        type="input"
                        label={"Style Link"}
                        key={index}
                        field={field}
                        onFieldChange={(newField) => {
                          const updatedFields = [...styleLinks];
                          updatedFields[index] = newField;
                          setStyleLinks(updatedFields);
                        }}
                        indexField={index}
                        onRemove={() =>
                          removeItemById(styleLinks, setStyleLinks, field.id)
                        }
                        inputIdPrefix={index}
                      />
                    </React.Fragment>
                  ))}
                  <Button
                    className="clickable add-button mb-3 ms-auto d-flex mt-3"
                    onClick={() => addField(setStyleLinks, styleLinks)}
                  >
                    Add More links
                  </Button>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as="textarea"
                      rows="8"
                      name="custom_css"
                      value={formData?.custom_css}
                      style={{ height: "auto" }}
                      id="custom_css"
                      placeholder=" Enter your event custom_css"
                      onChange={(e) => {
                        handleFieldChange("custom_css", e.target.value);
                      }}
                    />
                    <Form.Label className="form-label" htmlFor="custom_css">
                      Custom CSS
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <h5 className="theme-text-2">Script</h5>
                  <hr className="mb-2" />
                </Col>
                <Col md={12} className="ps-0">
                  {scriptLinks?.map((field, index) => (
                    <React.Fragment key={index}>
                      <SingleFieldset
                        fieldKey={"script_link"}
                        type="input"
                        label={"Script Link"}
                        key={index}
                        field={field}
                        onFieldChange={(newField) => {
                          const updatedFields = [...scriptLinks];
                          updatedFields[index] = newField;
                          setScriptLinks(updatedFields);
                        }}
                        indexField={index}
                        onRemove={() =>
                          removeItemById(scriptLinks, setScriptLinks, field.id)
                        }
                        inputIdPrefix={index}
                      />
                    </React.Fragment>
                  ))}
                  <Button
                    className="clickable add-button mb-3 ms-auto d-flex mt-3"
                    onClick={() => addField(setScriptLinks, scriptLinks)}
                  >
                    Add More links
                  </Button>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as="textarea"
                      rows="8"
                      name="custom_js"
                      value={formData?.custom_js}
                      style={{ height: "auto" }}
                      id="custom_js"
                      placeholder=" Enter your event custom_js"
                      onChange={(e) => {
                        handleFieldChange("custom_js", e.target.value);
                      }}
                    />
                    <Form.Label className="form-label" htmlFor="custom_js">
                      Custom JS
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <h5 className="theme-text-2">HTML Content</h5>
                  <hr className="mb-2" />
                </Col>
                <Col md={12}>
                  <span className="d-block text-end text-dark">
                    required<span className="text-danger">*</span>
                  </span>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as="textarea"
                      className={`${
                        isValid && hasValue && !formData.html_content
                          ? "border-danger"
                          : ""
                      }`}
                      rows="8"
                      name="html_content"
                      value={formData?.html_content}
                      style={{ height: "auto" }}
                      required
                      id="html_content"
                      placeholder=" Enter your event html_content"
                      onChange={(e) => {
                        handleFieldChange("html_content", e.target.value);
                      }}
                    />
                    <Form.Label className="form-label" htmlFor="html_content">
                      HTML Content
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <Button type="submit" className="next demosEditBtn">
                    Submit
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </Row>
      )}
    </Container>
  );
}

export default withRouter(AddEditPage);
