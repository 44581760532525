import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import * as Actions from "../../constants/baseUrl";
import "./payment.css";
import CustomLoader from "../../components/loader/loader";

const Pay = () => {
  const navigate = useNavigate();
  const myToken = localStorage.getItem("token");
  const siteName = SubDomainRouter("title");

  const [packageName, setPackageName] = useState("");
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    customername: "",
    name: "",
    number: "",
    expiryYear: "",
    expiryMonth: "",
    cvc: "",
    currency: "",
    quantity: 1,
    amount: 0,
    total_amount: 0,
    customerId: "",
    receiptEmail: "",
    description: "",
  });
  const [selectedItemDetails, setSelectedItemDetails] = useState({});

  const getQuery = (keys) => {
    const name = new URLSearchParams(window.location.search).get(keys);
    return name;
  };

  const handleFieldChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const getEntityItemData = async (entityType, entityId) => {
    if (entityType == null || entityId == null) {
      setLoading(false);
      return null;
    }

    try {
      const response = await axios.get(
        `${Actions.BASE_URL}${entityType}s/${entityId}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setSelectedItemDetails(response.data);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const entityType = getQuery("entityType");
    const entityId = getQuery("entityId");
    const currencyQuery = getQuery("currency");
    const amountQuery = getQuery("amount");
    const emailQuery = getQuery("email");
    const queryPackageName = getQuery("package");

    setFormData((prevState) => ({
      ...prevState,
      currency: currencyQuery || "",
      amount: amountQuery || 0,
      email: emailQuery || "",
    }));
    setPackageName((queryPackageName || "") + " subscription package");

    getEntityItemData(entityType, entityId);
  }, []);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      total_amount:
        Number(prevState.amount ?? 0) * Number(prevState.quantity ?? 1),
    }));
  }, [formData.amount, formData.quantity]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);

    var data = {
      customer: {
        email: formData.email,
        name: formData.customername,
        card: {
          name: formData.customername,
          number: formData.number,
          expiryYear: formData.expiryYear,
          expiryMonth: formData.expiryMonth,
          cvc: formData.cvc,
        },
      },
      charge: {
        currency: formData.currency,
        amount: Number(parseInt(formData.total_amount) + "00"),
        customerId: "cus_O5Swke2kscZQrA",
        receiptEmail: formData.email,
        description: `Your Payment for ${
          selectedItemDetails?.type
            ? selectedItemDetails?.type?.toUpperCase() + " "
            : ""
        }${selectedItemDetails?.title || packageName} of amount ${
          formData?.currency?.toUpperCase() + "." + formData?.total_amount
        }`,
      },
    };

    axios
      .post(`${Actions.PAYMENT_URL}Stripe/pay`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        successAlert();
        if ("id" in selectedItemDetails) {
          navigate(
            `/${selectedItemDetails.type}s/details/${selectedItemDetails.id}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setReqLoading(false);
      });
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column gap-2 bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center text-capitalize">
              Payment Processed.
            </h5>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Payment | {siteName ? siteName : window.location.host}</title>
      </Helmet>
      {reqLoading ? (
        <>
          <span className="register-loader"></span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="py-5 my-5">
          <h2 className="theme-text-2 text-center py-3 mb-3">Payment</h2>
          <Form onSubmit={handleSubmit}>
            <Row className="payment-container justify-content-center">
              <Col md={6} className="payment-left">
                <h2 className="payheader theme-text-2 mb-md-4 mb-3">
                  Customer Details
                </h2>

                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-md-4 mb-3">
                  <Form.Control
                    type="text"
                    required
                    id="email"
                    name="email"
                    placeholder="rakesh@yahoo.com"
                    value={formData.email}
                    onChange={(e) => handleFieldChange("email", e.target.value)}
                  />
                  <label htmlFor="email">Email</label>
                </Form.Group>

                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-md-4 mb-3">
                  <Form.Control
                    type="text"
                    required
                    id="name"
                    name="name"
                    placeholder="John More Doe"
                    onChange={(e) =>
                      handleFieldChange("customername", e.target.value)
                    }
                  />
                  <label htmlFor="name">Name on Card</label>
                </Form.Group>

                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-md-4 mb-3">
                  <Form.Control
                    type="text"
                    required
                    id="number"
                    name="number"
                    placeholder="4242-4242-4242-4242"
                    onChange={(e) =>
                      handleFieldChange("number", e.target.value)
                    }
                  />
                  <label htmlFor="ccnum">Credit card number</label>
                </Form.Group>

                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Row className="mb-md-0 mb-3 mx-0">
                  <Col className="px-0">
                    <Form.Group className="form-floating">
                      <Form.Control
                        type="text"
                        required
                        className="rounded-end-0"
                        id="expiryMonth"
                        name="expiryMonth"
                        placeholder="05"
                        onChange={(e) =>
                          handleFieldChange("expiryMonth", e.target.value)
                        }
                      />
                      <label htmlFor="expiryMonth">Exp Month</label>
                    </Form.Group>
                  </Col>
                  <Col className="px-0">
                    <Form.Group className="form-floating">
                      <Form.Control
                        type="text"
                        required
                        id="expiryYear"
                        name="expiryYear"
                        className="mid-field"
                        placeholder="2025"
                        onChange={(e) =>
                          handleFieldChange("expiryYear", e.target.value)
                        }
                      />
                      <label htmlFor="expiryYear">Exp Year</label>
                    </Form.Group>
                  </Col>
                  <Col className="px-0">
                    <Form.Group className="form-floating">
                      <Form.Control
                        type="text"
                        required
                        className="rounded-start-0"
                        id="cvv"
                        name="cvv"
                        placeholder="352"
                        onChange={(e) =>
                          handleFieldChange("cvc", e.target.value)
                        }
                      />
                      <label htmlFor="cvv">CVC</label>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col
                md={6}
                className="payment-right flex-column d-flex justify-content-between"
              >
                <div>
                  <h2 className="payheader theme-text-2 mb-md-4 mb-3">
                    Charge
                  </h2>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>{" "}
                  <Form.Group className="form-floating mb-md-4 mb-3">
                    <select
                      id="currency"
                      required
                      className="form-control form-select text-uppercase"
                      name="currency"
                      placeholder={"usd"}
                      value={formData.currency}
                      onChange={(e) =>
                        handleFieldChange("currency", e.target.value)
                      }
                    >
                      <option value="">Currency</option>
                      <option value="usd">usd</option>
                      <option value="cad">cad</option>
                      <option value="inr">inr</option>
                      <option value="eur">eur</option>
                      <option value="gbp">gbp</option>
                    </select>
                  </Form.Group>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-md-4 mb-3">
                    <Form.Control
                      type="number"
                      required
                      id="quantity"
                      name="quantity"
                      placeholder="1"
                      value={formData?.quantity ?? 1}
                      onChange={(e) =>
                        handleFieldChange("quantity", e.target.value)
                      }
                    />
                    <label htmlFor="quantity">Quantity </label>
                  </Form.Group>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-md-4 mb-3">
                    <Form.Control
                      type="number"
                      required
                      id="amount"
                      name="amount"
                      placeholder="1"
                      value={formData?.amount ?? 1}
                      onChange={(e) =>
                        handleFieldChange("amount", e.target.value)
                      }
                    />
                    <label htmlFor="quantity">Amount </label>
                  </Form.Group>
                  <small className="text-end d-block text-dark opacity-0 invisible">
                    required<span className="text-danger">*</span>
                  </small>{" "}
                  <Form.Group className="form-floating mb-md-4 mb-3">
                    <Form.Control
                      type="text"
                      id="total_amount"
                      name="total_amount"
                      placeholder="0"
                      value={formData?.total_amount}
                      disabled
                      readOnly
                      onChange={(e) =>
                        handleFieldChange("total_amount", e.target.value)
                      }
                    />
                    <label htmlFor="amount">Total Amount </label>
                    <span className="position-absolute end-0 h-100 top-0 d-flex align-items-center pe-3">
                      .00
                    </span>
                  </Form.Group>
                </div>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as="textarea"
                    style={{ height: "auto" }}
                    rows={4}
                    className="rounded-start-0"
                    id="description"
                    name="description"
                    placeholder="352"
                    onChange={(e) =>
                      handleFieldChange("description", e.target.value)
                    }
                  />
                  <label htmlFor="description">Comments</label>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Button
                  type="submit"
                  name="submit"
                  className="btn w-100 py-2 bg-theme add-button"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};

export default Pay;
